.mermaidTooltip {
  background: #0e262e;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 5px;
  position: fixed;
}

/*# sourceMappingURL=index.aa218974.css.map */
